//@author travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { DropDownPaginatedModel } from "../../../pojo/DropDownPaginatedModel";
import { GenericDropdownUtil } from "./generic.dropdown.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25DropdownPaginatedComponent } from "../s25.dropdown.paginated.component";

@TypeManagerDecorator("s25-ng-generic-multiselect-dropdown")
@Component({
    selector: "s25-ng-generic-multiselect-dropdown",
    template: `
        @if (this.items) {
            <s25-dropdown-paginated
                [choice]="choice"
                [model]="this.model"
                (chosenChange)="onChosenChange($event)"
                (isOpenEmit)="onToggle($event)"
                (focusElInParent)="focusDoneBtn()"
            >
                <span options>
                    <ng-content select="[topContent]"></ng-content>
                    @if (hasDone && showDone) {
                        <button
                            class="btn btn-primary ngMultiselectDDDone"
                            (keydown.tab)="focusSearchInput()"
                            (click)="emitDone()"
                        >
                            Done
                        </button>
                    }
                </span>
            </s25-dropdown-paginated>
        }

        <ng-template #choice let-item="item">
            <div>
                <input
                    tabindex="-1"
                    type="checkbox"
                    [checked]="item.isSelected"
                    [attr.aria-label]="'Checkbox to select or deselect ' + item.itemDesc || item.itemName"
                />
                <span [innerHtml]="item.txt"></span>
                @if (item.itemDesc) {
                    <span>{{ item.itemDesc }}</span>
                }
            </div>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25GenericMultiselectDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() placeholder: string;
    @Input() onDone: Function;
    @Input() items: DropDownItem[];
    @Input() chosen: DropDownItem[];
    @Input() alwaysShowDone?: boolean = true; //May want to hide done button dropdown is closed.
    @Input() hasDone: boolean;
    @Input() groupProp: string = "grp";
    @Input() emptyText: string;

    @Output() done = new EventEmitter<DropDownItem[]>();

    @ViewChild(S25DropdownPaginatedComponent) dropdownComp: S25DropdownPaginatedComponent;

    model: DropDownPaginatedModel;
    showDone: boolean;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {
        super();
    }

    ngOnInit() {
        this.chosen ??= [];
        this.model = {
            items: GenericDropdownUtil.extractItems(this.items),
            itemNameProp: "txt",
            groupNameProp: this.groupProp,
            placeholder: this.placeholder || "Select items",
            emptyText: this.emptyText || "", //no empty text bc some may be loaded on the fly (add param if desired...)
            selectOverride: (item: DropDownItem) => {
                //override select in drop-down-paginated with this (otherwise ddp will select the item, unselect all others, and close itself...)
                item.isSelected = !item.isSelected;
                if (item.isSelected) {
                    this.chosen.push(item);
                } else {
                    const key = this.getKey(item);
                    S25Util.array.inplaceFilter(this.chosen, (c) => this.getKey(c) !== key, true);
                }
                this.chosenChange.emit(this.chosen);
            },
            serverSide: this.serverSide,
            searchEnabled: S25Util.toBool(this.searchEnabled),
            autoOpen: S25Util.toBool(this.autoOpen),
            focusFirst: S25Util.toBool(this.focusFirst),
            alwaysOpen: S25Util.toBool(this.alwaysOpen),
            hideSearch: S25Util.toBool(this.hideSearch),
            resetSelectedOnCleanup: S25Util.toBool(this.resetSelectedOnCleanup),
        };

        this.apiBean = this.apiBean || {};
        S25Util.extend(this.apiBean, {
            getItems: () => {
                return this.model.items || [];
            },
            getFirstElementId: () => {
                return this.model.getFirstElementId();
            }, //Exposed via dropdown-paginated
            getHighlightedItem: () => {
                return this.model.getHighlightedItem();
            }, //Exposed via dropdown-paginated
        });

        this.showDone = this.alwaysShowDone;

        this.cd.detectChanges();

        if (this.chosen?.length) {
            this.updateModelItems();
        }
    }

    emitDone() {
        this.onDone && this.onDone({ items: this.chosen });
        this.done.emit(this.chosen);
    }

    onToggle(isOpen: boolean) {
        this.showDone = this.alwaysShowDone || isOpen;

        if (isOpen) {
            this.updateModelItems();
        } else if (!this.hasDone) {
            // Run done logic when dropdown closes if there is no done button
            this.emitDone();
        }

        this.cd.detectChanges();
    }

    getKey(item: DropDownItem) {
        return item.val ?? item.itemId;
    }

    updateModelItems() {
        const selected = new Set(this.chosen.map((item) => this.getKey(item)));
        for (const item of this.model.items) {
            item.isSelected = selected.has(this.getKey(item));
        }
    }

    focusDoneBtn() {
        this.elementRef.nativeElement.querySelector(".ngMultiselectDDDone")?.focus();
    }

    focusSearchInput() {
        //when tabbing from Done button, focus search input in S25DropdownPaginatedComponent
        this.dropdownComp?.focusSearchInput();
    }
}
